import { Controller } from "stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "decision",
    "bankAccountConfirmation",
    "replacementDirectDebitDate",
    "bankAccountDetailsIncorrectMessage"
  ]

  connect() {
    this.initialiseVisibility()
  }

  decisionChanged(event) {
    this.handleDecisionChange(event.target)
  }

  bankAccountConfirmationChanged(event) {
    this.handleBankAccountConfirmationChange(event.target)
  }

  initialiseVisibility() {
    const decisionInput = this.getSelectedInput(this.decisionTarget)
    const confirmationInput = this.getSelectedInput(this.bankAccountConfirmationTarget)

    this.handleDecisionChange(decisionInput)
    this.handleBankAccountConfirmationChange(confirmationInput)
    this.updateVisibility(this.bankAccountDetailsIncorrectMessageTarget, false)
  }

  handleDecisionChange(target) {
    if (!this.hasBankAccountConfirmationTarget) return

    const isReplacementDirectDebit = target?.value === "replacement_direct_debit"

    this.updateVisibility(this.bankAccountConfirmationTarget, isReplacementDirectDebit)

    if (!isReplacementDirectDebit) {
      this.resetSection(this.bankAccountConfirmationTarget)
      this.resetSection(this.replacementDirectDebitDateTarget)
      this.updateVisibility(this.replacementDirectDebitDateTarget, false)
      this.updateVisibility(this.bankAccountDetailsIncorrectMessageTarget, false)
    }
  }

  handleBankAccountConfirmationChange(target) {
    if (!this.hasReplacementDirectDebitDateTarget) return

    const shouldRequestDate = target?.value === "confirm"
    this.updateVisibility(this.replacementDirectDebitDateTarget, shouldRequestDate)
    this.updateVisibility(this.bankAccountDetailsIncorrectMessageTarget, !shouldRequestDate)

    if (!shouldRequestDate) {
      this.resetSection(this.replacementDirectDebitDateTarget)
    }
  }

  updateVisibility(target, shouldShow) {
    shouldShow ? utils.show(target) : utils.hide(target)
  }

  resetSection(target) {
    if (!target) return

    target.querySelectorAll("label").forEach(label =>
      label.classList.remove("active")
    )
    target.querySelectorAll("input").forEach(input =>
      input.checked = false
    )
  }

  getSelectedInput(target) {
    return target?.querySelector("input:checked") || null
  }
}
